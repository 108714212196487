<script>
import DefaultLoginPage from './customLoginPage/defaultLoginPage'
import empregosagrologin from './customLoginPage/empregosagrologin'
import empregatransportelogin from './customLoginPage/empregatransportelogin'
import acatslogin from './customLoginPage/acatsLoginPage'
import Vivaelogin from './customLoginPage/vivaelogin'
import fenaconlogin from './customLoginPage/portalfenaconlogin'
import Wfgloballogin from './customLoginPage/wfgloballogin'
import courseralogin from './customLoginPage/courseralogin'
import acatelogin from './customLoginPage/acatelogin'
import govsplogin from './customLoginPage/govsplogin'
import govmtlogin from './customLoginPage/govmtlogin'
import govmglogin from './customLoginPage/govmglogin'
import goveslogin from './customLoginPage/goveslogin'
import senacsclogin from './customLoginPage/senacsclogin'
import decolaentregadoreslogin from './customLoginPage/decolaentregadoreslogin'
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',
  components: {
    DefaultLoginPage,
    empregosagrologin,
    empregatransportelogin,
    Vivaelogin,
    fenaconlogin,
    acatslogin,
    Wfgloballogin,
    courseralogin,
    acatelogin,
    govmtlogin,
    govmglogin,
    govsplogin,
    goveslogin,
    senacsclogin,
    decolaentregadoreslogin
  },
  data () {
    return {
      attemptingLogin: false,
      pwOrEmailInvalid: false
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.setLanguage(language)
    },
    async prepareUserData (data) {
      try {
        const newUserData = await this.$store.dispatch('attemptSynchronizeUser', data)
        this.$store.commit('updateUser', newUserData.data)
        await this.$store.dispatch('attemptGetUserWorkspaces')
      } catch (error) {
        await this.$store.dispatch('attemptGetUserWorkspaces', true)
        this.$store.commit('updateUser', data)
      }
    },
    async submit (formData) {
      try {
        this.attemptingLogin = true
        const response = await this.$store.dispatch('attemptPerformLogin', { email: formData.email, password: formData.password, workspace: this.$route.query.theme })
        this.setPlooralGlobalToken(response.data.token)
        const userData = response.data.user
        let isSupport = false
        isSupport = !!(userData && userData.isSupport)
        if (response.data && !isSupport) {
          try {
            await this.prepareUserData(response.data)
            this.$store.getters.getUserWorkspaces.map(workspace => {
              if (this.$route.name.includes(workspace.type)) {
                if (workspace.type === 'individual' || (this.$route.params.companyDomain && this.$route.params.companyDomain.toLowerCase() === workspace.subdomain.replace(/\s+/g, '').toLowerCase())) {
                  this.$store.commit('setSelectedWorkspace', workspace)
                }
              }
            })

            this.changeLanguage(userData.language)
            this.attemptingLogin = false

            if (this.$route.query.theme) this.$store.commit('updateSetThemeWorkspace', this.$route.query.theme)

            const uri = decodeURIComponent(this.$route.query.redirect)
            if (uri !== 'undefined') {
              location.href = uri
            } else this.$router.push({ name: 'workspace.selection.index' })
          } catch (error) {
            this.attemptingLogin = false
          }
        } else if (isSupport) {
          this.debounceEvent(() => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('support.user.not.allowed')
            })
          }, 1000)
        }
        this.$store.commit('setLanguageIsLoaded', true)
      } catch (error) {
        this.pwOrEmailInvalid = true
        this.attemptingLogin = false
      }
    }
  },
  computed: {
    theme () {
      return this.$route.query.theme
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    }
  },
  created () {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide')
  },
  beforeRouteLeave (to, from, next) {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    next()
  }
}
</script>
<template>
  <div class="login-page--container">
    <vivaelogin v-if="theme === 'vivae'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></vivaelogin>
    <fenaconlogin v-if="theme === 'portalfenacon'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></fenaconlogin>
    <wfgloballogin  v-else-if="theme === 'pwfglobal'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit">
    </wfgloballogin>
    <empregosagrologin v-else-if="theme === 'empregosagro'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></empregosagrologin>
    <empregatransportelogin v-else-if="theme === 'empregatransporte'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></empregatransportelogin>
    <acatslogin v-else-if="theme === 'acats'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></acatslogin>
    <courseralogin v-else-if="theme === 'coursera'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></courseralogin>
    <acatelogin v-if="theme === 'acate'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></acatelogin>
    <govsplogin v-else-if="theme === 'govsp'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></govsplogin>
    <govmglogin v-else-if="theme === 'govmg'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></govmglogin>
    <govmtlogin v-else-if="theme === 'govmt'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></govmtlogin>
    <goveslogin v-else-if="theme === 'goves'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></goveslogin>
    <senacsclogin v-else-if="theme === 'senacsc'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></senacsclogin>
    <decolaentregadoreslogin v-else-if="theme === 'decolaentregadores'" :isAttemptingLogin="attemptingLogin"
      :pwOrEmailInvalid="pwOrEmailInvalid" @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"
    ></decolaentregadoreslogin>
    <default-login-page v-else :isAttemptingLogin="attemptingLogin" :pwOrEmailInvalid="pwOrEmailInvalid"
      @disableErrorPw="pwOrEmailInvalid = false" @submit="submit"></default-login-page>
  </div>
</template>
<style lang="scss">
.login--container {
  max-width: 100%;
  width: 515px;

  .login-form--wrapper {
    padding: 45px 64px;
    border-radius: 10px;
    text-align: left;

    .registration-form-subtitle {
      @extend .subtitle--1;
      @extend .neutral-medium;
    }
  }

  @media only screen and (max-width: 768px) {
    .login-form--wrapper {
      border-radius: 10px 10px 0 0;
      padding: 36px 24px 80px;
    }
  }
}
</style>
