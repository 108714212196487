<script>
import { required, email } from 'vuelidate/lib/validators'
import AuthTemplate from '@/components/authentication/AuthTemplate'
import InputField from '@/components/general/InputField'
export default {
  name: 'vivaelogin',
  props: {
    isAttemptingLogin: {
      type: Boolean,
      default: false
    },
    pwOrEmailInvalid: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AuthTemplate,
    InputField
  },
  validations: {
    formData: {
      email: {
        required,
        email
      },
      password: {
        required,
        passwordOrEmailInvalid: {
          value: function () {
            return !this.pwOrEmailInvalid
          }
        }
      }
    }
  },
  data () {
    return {
      formData: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    submit () {
      if (this.formData.email && this.formData.password) this.$emit('submit', this.formData)
    }
  }
}
</script>
<template>
    <auth-template color="portalfenacon" :title="$t('global:welcome')" :subtitle="$t('login.page:subtitle')" logo="/assets/images/customers/portalfenacon.png">
      <template v-slot:image>
        <img src="/assets/images/customers/portalfenacon-hand.png" height="100%" />
      </template>
      <template v-slot:form>
      <div class="login--container">
        <v-card class="login-form--wrapper">
          <h4>{{ $t('login.form:title') }}</h4>
          <p class="login-form-subtitle">
            {{$t('login.form:subtitle.1')}} <router-link class="login-form-subtitle" to="/register?theme=portalfenacon">{{$t('login.form:subtitle')}}</router-link>
          </p>
          <input-field
            :disabled="isAttemptingLogin"
            type="email"
            autocomplete="username"
            class="mt-10"
            outlined
            v-model="formData.email"
            :placeholder="$t('registration.form:email')"
            :validation="$v.formData.email"
            :hide-validation="!$v.formData.email.$error && isMobile"
            @keydown.enter.prevent="submit"
            @click="$emit('disableErrorPw')"
          />
          <input-field
            :disabled="isAttemptingLogin"
            type="password"
            autocomplete="current-password"
            outlined
            v-model="formData.password"
            :placeholder="$t('login.form:password')"
            :validation="$v.formData.password"
            :hide-validation="!$v.formData.password.$error && isMobile"
            @keydown.enter="submit()"
            @click="$emit('disableErrorPw')"
          />
          <router-link class="login-form-subtitle" to='/recovery?theme=portalfenacon'>{{$t('login.form:recover')}}</router-link>
          <v-btn style="font-size: 16px;" class="mt-10 btn bold transform-unset" color="#4196B7" :loading="isAttemptingLogin" :dark="!($v.$invalid || isAttemptingLogin)" :disabled="$v.$invalid || isAttemptingLogin" large block @click.stop="submit()">{{ $t(isAttemptingLogin ? 'global:wait' : 'login.form:submit') }}</v-btn>
        </v-card>
      </div>
    </template>
  </auth-template>
</template>
